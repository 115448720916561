export const InternalRoutes = {
  login: "/login",
  activate: "/activate/:activationKey",
  forgotPassword: "/forgot_password",
  artistDirectory: "/artists",
  artistProfile: "/artists/:artistSlug",
  fundDirectory: "/companies",
  fundProfile: "/companies/:companySlug",
  featuredDealDirectory: "/deals",
  featuredDeal: "/deals/:dealId",
  inbox: "/chat/:chatId?",
  referrals: "/referral/:slug",
  artistTutorial: "/artist-tutorial",
  settings: "/settings/:subpage?",
  portfolio: "/portfolio",
  insights: "/insights"
} as const;
