import { FORM_ERROR } from "final-form";
import { Fragment, ReactElement } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Box, Flex, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../components/01_Core/Buttons/Button";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import TextButton from "../../../../../components/01_Core/Buttons/TextButton";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import PasswordInput from "../../../../../components/01_Core/Forms/Inputs/PasswordInput";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredEmailValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { useAuth } from "../../../hooks/useAuth";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import { ILocationState } from "./LoginPage";

export interface ILoginFormValues {
  email: string;
  password: string;
}

function LoginForm(): ReactElement {
  const { signIn } = useAuth();
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const onSubmit = (values: ILoginFormValues) => {
    return signIn(values.email, values.password).then(
      () => {
        history.replace(location.state?.from ?? "/");
      },
      () => ({ [FORM_ERROR]: "bad_inputs" })
    );
  };

  return (
    <Fragment>
      <GridItem sx={{ justifyContent: "center" }}>
        <Text variant={"h600"} mb={40} sx={{ textAlign: "center" }}>
          Log in to your account
        </Text>
      </GridItem>
      <Form initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
        {(formProps: FormRenderProps<ILoginFormValues>) => (
          <form>
            <Flex
              bg={"white100"}
              sx={{
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #EAE8E6",
                borderRadius: "4px",
                overflow: "hidden"
              }}
            >
              <Box p={[24, 48]} sx={{ width: "100%" }}>
                <AutoLayout
                  spacing={24}
                  dependentProps={{
                    direction: "vertical"
                  }}
                  resizingX={FillContainer()}
                >
                  <FieldBuilder
                    inputField={TextInput}
                    fieldName={"email"}
                    validator={requiredEmailValidator}
                    inputFieldProps={{
                      type: "email",
                      label: "Email",
                      placeholder: "name@example.com",
                      size: ["medium", "large", "large", "large"]
                    }}
                  />
                  <FieldBuilder
                    inputField={PasswordInput}
                    fieldName={"password"}
                    inputFieldProps={{
                      label: "Password",
                      placeholder: "Enter Password",
                      size: ["medium", "large", "large", "large"]
                    }}
                  />
                </AutoLayout>
                <Box mt={11} mb={26}>
                  <Text
                    variant={"h100"}
                    color={"#FF9900"}
                    sx={{
                      display:
                        formProps.hasSubmitErrors ||
                        formProps.hasValidationErrors
                          ? "initial"
                          : "none"
                    }}
                  >
                    {formProps.submitError === "bad_inputs" ? (
                      <Box>Please check your email or password.</Box>
                    ) : null}
                  </Text>
                </Box>
                <LoadingButton
                  variant={"primary"}
                  size={"large"}
                  disabled={false}
                  onClick={formProps.handleSubmit}
                  loading={formProps.submitting}
                  type={"submit"}
                  sx={{
                    width: "100%"
                  }}
                >
                  Log in
                </LoadingButton>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
      <GridItem sx={{ justifyContent: "center" }} mt={"12px"}>
        <Link to={InternalRoutes.forgotPassword}>
          <TextButton size={"medium"} type={"secondary"}>
            Forgot your password?
          </TextButton>
        </Link>
      </GridItem>
    </Fragment>
  );
}

export default LoginForm;
